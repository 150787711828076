<template>
    <b-modal size="xl"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-3">
            <h1 class="h2 font-noto-serif-tc font-weight-bolder">
                {{ group ? "Updating" : "Creating" }} Group {{ group ? `"${ name }"` : "" }}
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <form v-on:submit.prevent.stop="submit"
                v-on:reset="reset">
            <div class="form-row">
                <div class="col-lg-4">

                </div>
                <div class="col-lg-8">
                    <div class="form-row">
                        <b-form-group class="col-md-6"
                                      label-for="active"
                                      v-if="group">
                            <b-form-checkbox id="active"
                                             switch
                                             v-model="isActive">
                                {{ isActive ? "🟢Active" : "🔴Inactive" }}
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group class="col-md-12"
                                      label-for="name">
                            <template v-slot:label>
                                📛 Name <small class="text-danger">* Required</small>
                            </template>
                            <b-form-input id="name"
                                          class="bg-light rounded-pill"
                                          type="text"
                                          required
                                          v-model="name">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-6"
                                      label-for="region">
                            <template v-slot:label>
                                🌏 Region <small class="text-danger">* Required</small>
                            </template>
                            <b-form-select id="region"
                                           class="bg-light rounded-pill"
                                           required
                                           v-model="region">
                                <option v-for="{ _id, code, name } in regions"
                                        v-bind:key="_id"
                                        v-bind:value="code">
                                    {{ name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group class="col-md-6"
                                      label-for="category">
                            <template v-slot:label>
                                🏷️ Category <small class="text-danger">* Required</small>
                            </template>
                            <b-form-select id="category"
                                           class="bg-light rounded-pill"
                                           required
                                           v-bind:disabled="!region"
                                           v-model="category">
                                <option v-for="{ _id, name } in categories"
                                        v-bind:key="_id"
                                        v-bind:value="_id">
                                    {{ name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <!-- <b-form-group class="col-md-8"
                                      label-for="chat">

                        </b-form-group> -->
                        <b-form-group class="col-md-12"
                                      label-for="link">
                            <template v-slot:label>
                                🔗 Link <small class="text-danger">* Required</small>
                            </template>
                            <b-form-input id="link"
                                          class="bg-light rounded-pill"
                                          type="text"
                                          required
                                          v-model="link">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-12"
                                      label-for="leader">
                            <template v-slot:label>
                                🤵 Leader <small class="text-danger">* Required</small>
                                <button class="btn btn-link"
                                        v-on:click.stop.prevent="isLeaderString = !isLeaderString">
                                    {{ isLeaderString ? "Select from users" : "Customize" }}
                                </button>
                            </template>
                            <v-select id="leader"
                                      class="organizer-selector form-control bg-light rounded-pill py-0"
                                      placeholder="Select the group leader from organizers"
                                      required
                                      v-if="!isLeaderString"
                                      v-bind:options="organizers"
                                      v-bind:get-option-label="({ name, countryCode, phone }) => `${ name } (+${ countryCode } ${ phone })`"
                                      v-model="leaderUser">
                            </v-select>
                            <b-form-input id="leader"
                                          class="bg-light rounded-pill"
                                          type="text"
                                          required
                                          placeholder="Enter the name of the group leader"
                                          v-else
                                          v-model.trim="leader">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="buttons text-center">
                <button class="btn btn-primary rounded-pill px-4 mr-2"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
                <button class="btn btn-secondary rounded-pill px-4"
                        type="reset"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
    name: "ModalGroup",
    components: {
        BModal, BFormGroup, BFormInput, BFormSelect, BFormCheckbox,
        vSelect,
    },
    props: {
        value: {
            type: Boolean,
        },
        group: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            isLeaderString: false,

            isActive: true,
            name: "",
            region: "",
            category: "",
            chat: "",
            link: "",
            leader: "",
            leaderUser: "",
        };
    },
    computed: {
        categories () {
            return this.region ? this.$store.getters["group/categoriesByRegion"](this.region) : [];
        },
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
        organizers () {
            return [
                ...this.$store.getters["users/itemsByRole"]("admin"),
                ...this.$store.getters["users/itemsByRole"]("organizer")
            ];
        },
        chats () {
            return this.$store.getters["whatsapp/chats"];
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.isLoading = false;
            this.isLeaderString = false;

            this.isActive = true;
            this.name = "";
            this.region = "";
            this.category = "";
            this.chat = "";
            this.link = "";
            this.leader = "";
            this.leaderUser = "";
        },
        populate () {
            const {
                isActive, region,
                name, /*avatar,*/ category, chat, link, leader, leaderUser,
            } = this.group;

            this.isActive = isActive;
            this.name = name;
            this.region = region;
            this.category = category;
            this.chat = chat;
            this.link = link;
            this.leader = leader;
            this.leaderUser = leaderUser;
        },
        submit () {
            if (this.group) {
                this.update();
                return;
            }
            this.create();
        },
        async create () {
            const { isActive, /*avatar,*/ name, region, category, chat, link, leader, leaderUser, } = this;
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "group/add",
                    { isActive, /*avatar,*/ name, region, category, chat, link, leader, leaderUser, }
                );
                this.reset();
                this.hideModal();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async update () {
            const { isActive, /*avatar,*/ name, region, category, chat, link, leader, leaderUser } = this;
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "group/edit",
                    { "_id": this.group._id, isActive, /*avatar,*/ name, region, category, chat, link, leader, leaderUser }
                );
                this.hideModal();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted () {
        if (this.group) {
            this.populate();
        }
    },
    watch: {
        group (newVal) {
            if (newVal) {
                this.populate();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.header {
    top: 0;
    z-index: 1;
}

.buttons {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3
}
</style>

<style lang="scss">
.organizer-selector {
    .vs__dropdown-toggle {
        height: 100%;
        border: none;
    }
}
</style>
